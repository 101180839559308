import React, { Fragment } from 'react'
import ResetPassswordForm from '../Components/ResetPassword/ResetPassswordForm'

function ForgetPassword() {
    return (
        <Fragment>
            <div className='container-fluid login'>
                <div className="row login">
                    <ResetPassswordForm />
                </div>
            </div>
        </Fragment>
    )
}

export default ForgetPassword