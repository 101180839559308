import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  message: null,
  error: null,
  data: {},
};

const ServiceDeleteSlice = createSlice({
  name: "servicedelete",
  initialState,
  reducers: {
    createServiceDeleteStart(state) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    createServiceDeleteSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.data = action.payload.data.data;
      state.message = action.payload.message;
    },
    logoutServiceDeleteSuccess(state, action) {
      state.loading = false;
      state.data = {};
      state.message = action.payload;
      state.token = null;
    },

    createServiceDeleteFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
    clearState: (state) => {
        state.error = null;
        state.success = null;
        state.deleteError = null;
        state.deleteSuccess = null;
        state.postError = null;
        state.postSuccess = null;
        state.updateError = null;
        state.updateSuccess = null;
      },
  },
});

export const {
  createServiceDeleteStart,
  createServiceDeleteSuccess,
  logoutServiceDeleteSuccess,
  createServiceDeleteFailure,
  clearError,
  clearMessage,
  clearState,
} = ServiceDeleteSlice.actions;

export default ServiceDeleteSlice.reducer;