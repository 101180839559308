import React, { Fragment, useEffect, useState } from "react";
import QRFilter from "./QRFilter";
import Bin from "../../Assets/Bin.png";
import { Button, message, Select, Spin, Checkbox } from "antd";
import { Modal } from "react-bootstrap";
import closeBtn from "../../Assets/cross.png";
import DeleteModaal from "./DeleteModaal";
import ClaimedUnclaimed from "./ClaimedUnclaimed";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { qrListing } from "../../Redux/Actions/qrListing";
import Pagination from "../Pagination";
import { ServiceDeleteData } from "../../Redux/Actions/DeleterList";
import { clearState } from "../../Redux/Reducers/ServiceDelete";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const Listing = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [loader, setLoader] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState("svg");
  const [markClaim, setMarkClaim] = useState(false);
  const [page, setPage] = useState(1);
  const [id, setId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [newId, setNewID] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { deleteError, deleteSuccess } = useSelector(
    (state) => state.DeleteList
  );
  const { data } = useSelector((state) => state.QRlisting);
  const handleCloseClaim = () => setMarkClaim(false);
  const handleFormatChange = (value) => {
    setDownloadFormat(value);
  };
  const handleShowClaim = (data) => {
    setMarkClaim(!markClaim);
    setNewID(data);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(qrListing({ page, pageSize })).finally(() => setLoading(false));
  }, [dispatch, page, pageSize]);

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const handleModalOpen = (id) => {
    setId(id);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleDelete = async (id) => {
    dispatch(ServiceDeleteData(id));
  };

  const handleSelectItem = (id) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(id)
        ? prevSelectedItems.filter((item) => item !== id)
        : [...prevSelectedItems, id]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedItems(data.items.map((item) => item._id));
    } else {
      setSelectedItems([]);
    }
  };

  const svgToBase64 = (svgString) => {
    return `data:image/svg+xml;base64,${window.btoa(
      unescape(encodeURIComponent(svgString))
    )}`;
  };

  const HandlePrintReadyPDF = async () => {
    try {
      setLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/PrintQrAsPdf`,
        { ids: selectedItems },
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "qr_codes_pdf.zip";

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setLoader(false);

      setSelectedItems([]);
    } catch (error) {
      console.error("Error downloading ZIP file:", error);
    }
  };

  const downloadImage = () => {
    setLoader(true);
    const zip = new JSZip();

    selectedItems.forEach((itemId) => {
      const svgElement = document.getElementById(`svg-${itemId}`);
      if (!svgElement) return;

      const svgData = new XMLSerializer().serializeToString(svgElement);

      // Add SVG data to the zip file
      zip.file(`image-${itemId}.svg`, svgData);
    });

    // Generate the zip file asynchronously
    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        // Save the zip file using FileSaver.js
        saveAs(content, "QR.zip");
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error creating zip file:", error);
      });
  };

  const HandlePrintV2 = async (data) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/PrintQr/${data._id}`,
        {
          responseType: "blob", // Set the response type to 'blob'
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "download.pdf";

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (deleteError) {
      message.error(deleteError);
      dispatch(clearState());
    }
    if (deleteSuccess) {
      message.success(deleteSuccess);
      dispatch(clearState());
    }
  }, [deleteError, deleteSuccess, dispatch]);
  console.log(data.items, "<========datas");
  return (
    <Fragment>
      <div className="container-fluid qrr">
        <div className="row noPad">
          <div className="col-sm-12">
            <QRFilter
              HandlePrintReadyPDF={HandlePrintReadyPDF}
              downloadImage={downloadImage}
              loader={loader}
            />
          </div>
          <div className="col-sm-12 qrTable">
            <Spin spinning={loading}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <Checkbox
                        onChange={handleSelectAll}
                        checked={selectedItems.length === data?.items?.length}
                      />
                      <br />
                    </th>
                    <th>SNo</th>
                    <th>Customer Name</th>
                    <th>Status</th>
                    <th>QR</th>
                    <th>Customer Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.items?.map((datas, i) => (
                    <tr key={datas._id}>
                      <td>
                        <Checkbox
                          checked={selectedItems.includes(datas._id)}
                          onChange={() => handleSelectItem(datas._id)}
                        />
                      </td>
                      <td className="assd">
                        <Link to={`/qr-management/${datas?._id}`}>
                          {Number(data.offset + 1) + i}
                        </Link>
                      </td>
                      <td className="assd">
                        <Link to={`/qr-management/${datas?._id}`}>
                          {datas?.QrCodes[0] &&
                            datas?.QrCodes[0]?.UserInfos?.Name}
                        </Link>
                      </td>
                      <td className="assd">
                        <Link to={`/qr-management/${datas?._id}`}>
                          <span
                            className={`dot ${
                              datas?.IsActive === true ? "green" : "red"
                            }`}
                          ></span>
                          {datas?.IsActive === true ? "Sold" : "UnSold"}
                        </Link>
                      </td>
                      <td style={{ display: "none" }}>
                        <svg
                          id={`svg-${datas._id}`}
                          className="bboddy"
                          style={{ strokeWidth: "3" }}
                          dangerouslySetInnerHTML={{
                            __html: `${datas.SvgPath.replace(
                              /^<div>|<\/div>$/g,
                              ""
                            )}`,
                          }}
                        />
                      </td>
                      <td className="assd">
                        <Link to={`/qr-management/${datas?._id}`}>
                          <img
                            src={datas?.QrMedia}
                            alt="qrimage"
                            className="qrimagetable"
                          />
                        </Link>
                      </td>
                      <td className="assd emailAlso">
                        <Link to={`/qr-management/${datas?._id}`}>
                          {datas?.QrCodes[0] &&
                            datas?.QrCodes[0]?.UserInfos?.Email}
                        </Link>
                      </td>

                      <td className="actionBtns">
                        <Button
                          className="priintBtnb"
                          onClick={() => HandlePrintV2(datas)}
                        >
                          Print
                        </Button>
                        <Button
                          className="claimedBtn"
                          onClick={() => handleShowClaim(datas)}
                          disabled={!datas.IsActive}
                        >
                          {!datas?.IsActive ? "Reset" : "Reset"}
                        </Button>
                        <Button
                          className="delBtn"
                          onClick={() => handleModalOpen(datas?._id)}
                        >
                          <img src={Bin} alt="qrimage" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Pagination
                className="paginated"
                TotalData={data?.totalCount}
                handlePageChange={handlePageChange}
                currentPage={page}
              />
            </Spin>
          </div>
        </div>
      </div>
      <Modal show={modalOpen} onHide={handleModalClose} className="deleteModal">
        <Modal.Header>
          <Button onClick={handleModalClose}>
            <img src={closeBtn} alt="closebtn" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <DeleteModaal
            handleClose={handleModalClose}
            handleDelete={handleDelete}
            id={id}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={markClaim}
        onHide={handleCloseClaim}
        className="deleteModal claimedUnclaimed"
      >
        <Modal.Header>
          <Button onClick={handleCloseClaim}>
            <img src={closeBtn} alt="closebtn" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <ClaimedUnclaimed handleClose={handleCloseClaim} data={newId} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Listing;