import React, { Fragment } from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import Headers from "../Components/Headerr/Headers";
import Listing from "../Components/FaqContent/Lisiting";
import FaqHeader from "../Components/FaqContent/FaqHeader";

function Faq() {
  return (
    <Fragment>
      <div class="container-fluid main">
        <div class="row">
          <div className="col-sm-3 sideebarr">
            <Sidebar />
          </div>

          <div className="col-sm-9 sideebarr">
            <Headers />
            <FaqHeader />
            <Listing />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Faq;
