import React, { Fragment } from 'react'
import ResetCodeForm from '../Components/ResetPassword/ResetCodeForm'

function ResetCode() {
    return (
        <Fragment>
            <div className='container-fluid login'>
                <div className="row login">
                    <ResetCodeForm />
                </div>
            </div>
        </Fragment>
    )
}

export default ResetCode