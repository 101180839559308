import React, { Fragment, useEffect } from 'react'
import logo from '../../Assets/logoLogin.png'
import { Button, Form, Input, message } from 'antd'
import frwd from '../../Assets/rightArrow.png'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ResetEmail } from '../../Redux/Actions/ResetEmail'
import { clearState } from '../../Redux/Reducers/ResetEmailReducer'

const ResetPassswordForm = () => {
    const navigate = useNavigate()
    const { error, success } = useSelector((state) => state.Rmail);
    const Dispatch = useDispatch();
    const onFinish = async (values) => {
        await Dispatch(ResetEmail({ values }));
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (error) {
            message.error(error);
            Dispatch(clearState());
        }
        if (success) {
            message.success(success);
            navigate("/reset-code");
            Dispatch(clearState());
        }
    }, [error, success, Dispatch]);
    return (
        <Fragment>
            <div className="container-fluid loginForm">
                <Form
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='off'
                    layout='vertical'
                >
                    <div className="row">
                        <div className="col-sm-12 cennn">
                            <img src={logo} alt='asd'/>
                        </div>

                        <div className="col-sm-12 whiteBorder">
                            <Form.Item 
                                name='Email'
                                label='Email'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter email!'
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address!'
                                    }
                                ]}
                            >
                                <Input placeholder='Email' />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12">
                            <Button htmlType='submit' type='primary'>Reset <img src={frwd} alt='asd'/></Button>
                        </div>
                        <div className="col-sm-12 cennn">
                            <p>Do you want to Login? <span style={{textDecoration: 'underline'}} onClick={() => navigate('/')}>Login</span></p>
                        </div>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default ResetPassswordForm
