import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./Reducers/authReducer";
import ProfileReducer from "./Reducers/ProfileReducer";
import qrListReducers from "./Reducers/qrListReducers";
import SingleListReducer from "./Reducers/SingleListReducer";
import ProfileUpdateReducers from "./Reducers/ProfileUpdateReducers";
import ResetPassReducer from "./Reducers/ResetPassReducer";
import ResetEmailReducer from "./Reducers/ResetEmailReducer";
import ResetCodeReducers from "./Reducers/ResetCodeReducers";
import ForgetPassReducer from "./Reducers/ForgetPassReducer";
import CountReducers from "./Reducers/CountReducers";
import ServiceDelete from "./Reducers/ServiceDelete";
import claimAndUnclaim from "./Reducers/claimAndUnclaimReducer";
import FaqReducer from "./Reducers/FaqReducer";
// import qrListReducer from "./Reducers/qrListReducer";

const rootReducer = combineReducers({
  user: authReducer,
  profileget: ProfileReducer,
  QRlisting: qrListReducers,
  SingleList: SingleListReducer,
  ProfUpd: ProfileUpdateReducers,
  Reset: ResetPassReducer,
  Rmail: ResetEmailReducer,
  Rcode: ResetCodeReducers,
  Fpass: ForgetPassReducer,
  Dcount: CountReducers,
  DeleteList: ServiceDelete,
  claimAndUnclaim: claimAndUnclaim,
  FaqReducer: FaqReducer,
});
export default rootReducer;
