import React, { Fragment } from "react";

const FaqHeader = () => {
  return (
    <Fragment>
      <div className="container-fluid dasshboard QR">
        <div className="row">
          <div className="col-sm-12">
            <p>FAQ</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FaqHeader;
