import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  success: null,
  error: null,
  data: {},
};

const ResetPassSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    createResetPassStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.success = null;
    },

    createResetPassSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.success = "Update Successfully";
    //   console.log("asdsad" ,action.payload)
    },
    logoutResetPassSuccess(state, action) {
      state.loading = false;
      state.data = {};
      state.success = action.payload;
      state.token = null;
    },

    createResetPassFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearsuccess: (state) => {
      state.success = null;
    },
    clearState: (state) => {
        state.error = null;
        state.success = null;
        state.editSuccess = null;
        state.editError = null;
        state.passwordSuccess = null;
        state.passwordError = null;
      },
  },
});

export const {
  createResetPassStart,
  createResetPassSuccess,
  logoutResetPassSuccess,
  createResetPassFailure,
  clearError,
  clearsuccess,
  clearState,
} = ResetPassSlice.actions;

export default ResetPassSlice.reducer;