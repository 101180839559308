import { createAsyncThunk } from "@reduxjs/toolkit";
import { createProfileUpdateStart, createProfileUpdateSuccess, createProfileUpdateFailure } from "../Reducers/ProfileUpdateReducers";
import axios from "axios";
import Cookies from "js-cookie";

export const ProfileUpdate = createAsyncThunk(
  "ProfileUpdate",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(createProfileUpdateStart());
      const token = Cookies.get("token");

      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}AdminRoutes/EditAdmin`,
        values,
        config
      );

      thunkAPI.dispatch(createProfileUpdateSuccess(response.data));

      return response.data;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        createProfileUpdateFailure(error.response.data.message)
      ); // Dispatch the failure action
      return thunkAPI.rejectWithValue(error.response.data.message); // Return a rejected action with the error message
    }
  }
);