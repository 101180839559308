import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  success: null,
  error: null,
  data: {},
  token: null, // Add token to the initial state
};

const ResetCodeSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    createResetCodeStart(state) {
      state.loading = true;
      state.error = null;
      state.success = null;
    },

    createResetCodeSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.success = action.payload.message;
      state.token = action.payload.Token; // Store the token
    },
    logoutResetCodeSuccess(state, action) {
      state.loading = false;
      state.data = {};
      state.success = action.payload;
      state.token = null; // Clear the token
    },

    createResetCodeFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearsuccess: (state) => {
      state.success = null;
    },
    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.token = null; // Clear the token
      state.data = {}; // Clear the data if needed
    },
  },
});

export const {
  createResetCodeStart,
  createResetCodeSuccess,
  logoutResetCodeSuccess,
  createResetCodeFailure,
  clearError,
  clearsuccess,
  clearState,
} = ResetCodeSlice.actions;

export default ResetCodeSlice.reducer;