import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  editError: null,
  editSuccess: null,
  passwordError: null,
  passwordSuccess: null,
  data: {},
  token: null,
};

const CountSlice = createSlice({
  name: "Count",
  initialState,
  reducers: {
    CountStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    CountSuccess(state, action) {
      state.loading = false;
      state.data = action.payload?.data;
      state.success = action.payload.message;
    },
    CountFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getMyDetailsStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getMyDetailsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
    },
    getMyDetailsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateCountStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    updateCountSuccess(state, action) {
      state.loading = false;
      state.editSuccess = action.payload.data.message;
    },
    updateCountFailure(state, action) {
      state.loading = false;
      state.editError = action.payload;
    },
    changePasswordStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    changePasswordSuccess(state, action) {
      state.loading = false;
      state.passwordSuccess = action.payload.data.message;
    },
    changePasswordFailure(state, action) {
      state.loading = false;
      state.passwordError = action.payload;
    },
    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.editSuccess = null;
      state.editError = null;
      state.passwordSuccess = null;
      state.passwordError = null;
    },
  },
});

export const {
  CountStart,
  CountSuccess,
  CountFailure,
  getMyDetailsStart,
  getMyDetailsSuccess,
  getMyDetailsFailure,
  updateCountStart,
  updateCountSuccess,
  updateCountFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  clearState,
} = CountSlice.actions;

export default CountSlice.reducer;
