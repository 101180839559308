import React, { Fragment } from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import Headers from '../Components/Headerr/Headers'
import DashboardData from '../Components/Dashboard/DashboardData'

function Dashboard() {
    return (
        <Fragment>
            <div class="container-fluid main">
                <div class="row">
                    <div className='col-sm-3 sideebarr'>
                        <Sidebar />
                    </div>    

                    <div className='col-sm-9 sideebarr'>
                        <Headers />
                        <DashboardData />
                    </div>   
                </div>    
            </div>
        </Fragment>
    )
}

export default Dashboard