import { createAsyncThunk } from "@reduxjs/toolkit";
import { createResetCodeStart, createResetCodeSuccess, createResetCodeFailure } from "../Reducers/ResetCodeReducers";
import axios from "axios";
import Cookies from "js-cookie";

export const ResetCode = createAsyncThunk(
  "ResetCode",
  async ({ values }, thunkAPI) => {
    try {
      console.log("data", values);
      thunkAPI.dispatch(createResetCodeStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}AdminRoutes/ConfirmCode`,
        values
      );

      const token = response.data.token; // Assuming the token is in response.data.token

      thunkAPI.dispatch(createResetCodeSuccess( response.data )
      
    );
    Cookies.set("Token", response.data.Token)
      console.log(response, "popppresponse")

    
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(createResetCodeFailure(error.response.data.message)); // Dispatch the failure action
      return thunkAPI.rejectWithValue(error.response.data.message); // Return a rejected action with the error message
    }
  }
);