import React, { Fragment } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import logoo from '../../Assets/logoDashboard.png';
import dotImage from '../../Assets/dots.png';
import { useDispatch } from 'react-redux';
import { logOutSuccess } from "../../Redux/Reducers/authReducer";
import Cookies from "js-cookie";

const Sidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const handleLogout = async () => {
        await dispatch(logOutSuccess());
        Cookies.remove("token",  navigate("/") );
      };


    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 logoImage">
              <img src={logoo} alt="Logo" />
            </div>
            <div className="col-sm-12 lisst">
              <ul>
                <li>
                  <NavLink to="/dashboard" activeClassName="active">
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <img
                            src={dotImage}
                            alt="Active indicator"
                            className="active-indicator"
                          />
                        )}
                        DASHBOARD
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/qr-management" activeClassName="active">
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <img
                            src={dotImage}
                            alt="Active indicator"
                            className="active-indicator"
                          />
                        )}
                        QR Management
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/faq" activeClassName="active">
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <img
                            src={dotImage}
                            alt="Active indicator"
                            className="active-indicator"
                          />
                        )}
                        FAQ
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/profile" activeClassName="active">
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <img
                            src={dotImage}
                            alt="Active indicator"
                            className="active-indicator"
                          />
                        )}
                        PROFILE
                      </>
                    )}
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    to="/"
                    onClick={handleLogout}
                    activeClassName="active"
                  >
                    {({ isActive }) => (
                      <>
                        {isActive && (
                          <img
                            src={dotImage}
                            alt="Active indicator"
                            className="active-indicator"
                          />
                        )}
                        Logout
                      </>
                    )}
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
    );
};

export default Sidebar;
