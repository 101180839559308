import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login';
import ForgetPassword from './Pages/ForgetPassword';
import ResetCode from './Pages/ResetCode';
import NewPassword from './Pages/NewPassword';
import Dashboard from './Pages/Dashboard';
import QRManagement from './Pages/QRManagement';
import QRDetail from './Pages/QRDetail';
import Profile from './Pages/Profile';
import { useSelector } from 'react-redux';
import Cookies from "js-cookie";
import ProtectedRoute from './Components/ProtectedRoutes/ProtectedRoute';
import Faq from "./Pages/Faq";

function App() {
  const { token } = useSelector((state) => state.user);

  const userToken = Cookies.get("token");
  const tokens = token ? token : userToken;

  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/reset" element={<ForgetPassword />} />
      <Route exact path="/reset-code" element={<ResetCode />} />
      <Route exact path="/reset-password" element={<NewPassword />} />
      <Route
        exact
        path="/dashboard"
        element={
          <ProtectedRoute token={tokens}>
            {" "}
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/faq"
        element={
          <ProtectedRoute token={tokens}>
            {" "}
            <Faq />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/qr-management"
        element={
          <ProtectedRoute token={tokens}>
            {" "}
            <QRManagement />{" "}
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/qr-management/:id"
        element={
          <ProtectedRoute token={tokens}>
            <QRDetail />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/profile"
        element={
          <ProtectedRoute token={tokens}>
            <Profile />{" "}
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;

