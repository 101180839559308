import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  editError: null,
  editSuccess: null,
  passwordError: null,
  passwordSuccess: null,
  data: {},
  token: null,
};

const singleListSlice = createSlice({
  name: "singleList",
  initialState,
  reducers: {
    singleListStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    singleListSuccess(state, action) {
      state.loading = false;
      state.data = action.payload?.data?.data;
      state.success = action.payload.message;
    },
    singleListFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getMyDetailsStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getMyDetailsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
    },
    getMyDetailsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updatesingleListStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    updatesingleListSuccess(state, action) {
      state.loading = false;
      state.editSuccess = action.payload.data.message;
    },
    updatesingleListFailure(state, action) {
      state.loading = false;
      state.editError = action.payload;
    },
    changePasswordStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    changePasswordSuccess(state, action) {
      state.loading = false;
      state.passwordSuccess = action.payload.data.message;
    },
    changePasswordFailure(state, action) {
      state.loading = false;
      state.passwordError = action.payload;
    },
    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.editSuccess = null;
      state.editError = null;
      state.passwordSuccess = null;
      state.passwordError = null;
    },
  },
});

export const {
  singleListStart,
  singleListSuccess,
  singleListFailure,
  getMyDetailsStart,
  getMyDetailsSuccess,
  getMyDetailsFailure,
  updatesingleListStart,
  updatesingleListSuccess,
  updatesingleListFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  clearState,
} = singleListSlice.actions;

export default singleListSlice.reducer;
