import React, { Fragment, useEffect, useState } from 'react';
import edit from '../../Assets/edit.png';
import { Button, Form, Input,message } from 'antd';
import closeBtn from '../../Assets/cross.png';
import { Modal } from 'react-bootstrap';
import ChangePasswordModaal from './ChangePasswordModaal';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../../Redux/Actions/Profile';
import { ProfileUpdate } from '../../Redux/Actions/ProfileUpdate';
import { clearState } from "../../Redux/Reducers/ProfileUpdateReducers";

const ProfileDetailListing = () => {
    const [show, setShow] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleEditToggle = () => setIsEditing(!isEditing);
    const { error,  success} = useSelector((state) => state.ProfUpd) ;
    const Dispatch = useDispatch();
    const onFinish = async (values) => {
        const id = data._id 
        await  Dispatch(ProfileUpdate({values}));
        Dispatch ( Profile () )
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        if (error) {
            message.error(error);
            dispatch(clearState());
        }
        if (success) {
            message.success(success);
            navigate("/dashboard");
            dispatch(clearState());
        }
    }, [error, success, dispatch]);

    const { data } = useSelector((state) => state.profileget);
    useEffect(() => {
        dispatch(Profile());
    }, [dispatch]);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                FirstName: data?.FirstName,
                LastName: data?.LastName,
                Email: data?.Email
            });
        }
    }, [data, form]);

    return (
        <Fragment>
            <div className='container-fluid qrr'>
                <Form
                    layout='vertical'
                    autoComplete='off'
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >   
                    <div className="row noPad qrFOrm">
                        <div className="col-sm-12 qrimagepreview editbtn">
                            <Button className='edditBtn' onClick={handleEditToggle}>
                                <img src={edit} alt="Edit" />
                            </Button>
                        </div>
                        <div className="col-sm-12 nipus">
                            <Form.Item
                                label='First Name'
                                name='FirstName'
                            >
                                <Input placeholder='First Name' disabled={!isEditing} />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12 nipus">
                            <Form.Item
                                label='Last Name'
                                name='LastName'
                            >
                                <Input placeholder='Last Name' disabled={!isEditing} />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12 nipus">
                            <Form.Item
                                label='Email'
                                name='Email'
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email is not valid'
                                    }
                                ]}
                            >
                                <Input placeholder='Email' disabled={!isEditing} />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12 changePass">
                            <Button className='bttn' onClick={handleShow}>
                                Change Password
                            </Button>
                        </div>
                        
                        {
                            isEditing &&
                            <div className="col-sm-12 svaebtn">
                                <Button htmlType="submit">Save</Button>
                            </div>

                        }
                    </div>
                </Form>
            </div>

            <Modal show={show} onHide={handleClose} className='deleteModal profileModal'>
                <Modal.Header>
                    <p>Change Password</p>
                    <Button onClick={handleClose}>
                        <img src={closeBtn} alt='closebtn'/>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <ChangePasswordModaal handleClose={handleClose}/>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default ProfileDetailListing;
