import React from "react";
import { Pagination as AntPagination } from "antd";
    
const Pagination = ({
  TotalData,
  handlePageChange,
  currentPage,
 
}) => {
  return (
    <nav className="paginationMain pagination">
      <AntPagination
        total={TotalData}
        onChange={handlePageChange}
        defaultCurrent={currentPage}
        showTotal={(total) => `${"total"} ${total} ${"items"}`}
        size="large"
        showSizeChanger
      />
    </nav>
  );
};

export default Pagination;