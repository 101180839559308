import React, { Fragment, useEffect, useState } from "react";
import { Accordion, Modal } from "react-bootstrap";
import img1B from "../../Assets/1Brown.png";
import img1W from "../../Assets/1White.png";
import img2B from "../../Assets/2Brown.png";
import img2W from "../../Assets/2White.png";
import img3B from "../../Assets/3Brown.png";
import img3W from "../../Assets/3White.png";
import img4B from "../../Assets/4Brown.png";
import img4W from "../../Assets/4White.png";
import { useDispatch, useSelector } from "react-redux";
import { getFaqLisiting } from "../../Redux/Actions/faq";
import EditIcon from "../../Assets/edit.png";
import EditFaqForm from "./EditFaqForm";

const Lisiting = () => {
  const { data } = useSelector((state) => state.FaqReducer);
  const dispatch = useDispatch();
  const [show, setShow] = useState();
  const [singleData, setSingleData] = useState();

  const handleEditOpen = (data) => {
    setShow(!show);
    setSingleData(data);
  };
  const handleCloseEdit = () => {
    setShow(false);
    setSingleData();
  };
  useEffect(() => {
    dispatch(getFaqLisiting());
  }, [dispatch]);

  return (
    <Fragment>
      <div className="container-fluid qrr myTributess">
        <div className="row noMArr fform tribute faq">
          <div className="col-sm-12 widthSeventy">
            <Accordion defaultActiveKey={0}>
              {data?.map((item, i) => (
                <Accordion.Item eventKey={i} key={i}>
                  <Accordion.Header className="custom-accordion-header">
                    <div>
                      {i === 0 && <img src={img1B} alt="image1" />}
                      {i === 1 && <img src={img2B} alt="image2" />}
                      {i === 2 && <img src={img3B} alt="image3" />}
                      {i === 3 && <img src={img4B} alt="image4" />}
                    </div>
                    <div>
                      <h5>{item?.Question}</h5>
                    </div>
                    <div>
                      <img
                        onClick={() => handleEditOpen(item)}
                        src={EditIcon}
                        alt="edit icon"
                      />
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      {i === 0 && <img src={img1W} alt="image1W" />}
                      {i === 1 && <img src={img2W} alt="image2W" />}
                      {i === 2 && <img src={img3W} alt="image3W" />}
                      {i === 3 && <img src={img4W} alt="image4W" />}
                    </div>
                    <div>{item?.Answer}</div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleCloseEdit}
        className="uploaddVideoPhoto FAQmoDAL"
      >
        <Modal.Header closeButton>
          <h3>Edit FAQ</h3>
        </Modal.Header>

        <Modal.Body>
          <EditFaqForm
            handleClose={handleCloseEdit}
            editData={singleData}
            handleModalOpen={handleEditOpen}
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Lisiting;
