import React, { Fragment, useEffect } from 'react'
import logo from '../../Assets/logoLogin.png'
import { Button, Form, Input, message } from 'antd'
import frwd from '../../Assets/rightArrow.png'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { Login } from "../../Redux/Actions/auth";
import { clearState } from "../../Redux/Reducers/authReducer";
import Loader from '../Loader'

const Loggin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, error, success } = useSelector((state) => state.user);
    const onFinish = async (values) => {
        await dispatch(Login(values));
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    useEffect(() => {
        if (error) {
            message.error(error);
            dispatch(clearState());
        }
        if (success) {
            message.success(success);
            navigate("/qr-management");
            dispatch(clearState());
        }
    }, [error, success]);
    return (
        <Fragment>
            <div className="container-fluid loginForm">
                <Form
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                >
                    <div className="row">
                        <div className="col-sm-12 cennn">
                            <img src={logo} alt='asd' />
                        </div>

                        <div className="col-sm-12 whiteBorder">
                            <Form.Item
                                name="Email"
                                label="Admin Email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter Email!",
                                    },

                                ]}
                            >
                                <Input placeholder="Admin Email" />
                            </Form.Item>
                        </div>
                        <div className="col-sm-12">
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your password!",
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                        </div>
                        <div className="col-sm-12">
                        <Button
      htmlType="submit"
      type="primary"
      className="deletewtmodalBTn"
      disabled={loading}
    >
      {loading ? <Loader /> : (
        <>
          Login <img src={frwd} alt="asd" />
        </>
      )}
    </Button>
                        </div>
                        <div className="col-sm-12 cennn">
                            <p>Forget Password? <span style={{ textDecoration: 'underline' }} onClick={() => navigate('/reset')}>Reset</span></p>
                        </div>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default Loggin
