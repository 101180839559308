import { createAsyncThunk } from "@reduxjs/toolkit";
import { createResetEmailStart, createResetEmailSuccess, createResetEmailFailure } from "../Reducers/ResetEmailReducer";
import axios from "axios";

export const ResetEmail = createAsyncThunk(
  "ResetEmail",
  async ({ values }, thunkAPI) => {
    try {
      console.log("data", values);
      thunkAPI.dispatch(createResetEmailStart());

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}AdminRoutes/ForgotPassword`,
        values,
        // config
      );

      thunkAPI.dispatch(createResetEmailSuccess(response.data));

      return response.data;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(createResetEmailFailure(error.response.data.message)); // Dispatch the failure action
      return thunkAPI.rejectWithValue(error.response.data.message); // Return a rejected action with the error message
    }
  }
);