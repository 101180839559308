import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  success: null,
  error: null,
  data: {},
};

const ResetEmailSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    createResetEmailStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.success = null;
    },

    createResetEmailSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.success = action.payload.message;
    //   console.log("asdsad" ,action.payload)
    },
    logoutResetEmailSuccess(state, action) {
      state.loading = false;
      state.data = {};
      state.success = action.payload;
      state.token = null;
    },

    createResetEmailFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearsuccess: (state) => {
      state.success = null;
    },
    clearState: (state) => {
        state.error = null;
        state.success = null;
        state.editSuccess = null;
        state.editError = null;
        state.passwordSuccess = null;
        state.passwordError = null;
      },
  },
});

export const {
  createResetEmailStart,
  createResetEmailSuccess,
  logoutResetEmailSuccess,
  createResetEmailFailure,
  clearError,
  clearsuccess,
  clearState,
} = ResetEmailSlice.actions;

export default ResetEmailSlice.reducer;