import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  editError: null,
  editSuccess: null,
  passwordError: null,
  passwordSuccess: null,
  data: {},
  token: null,
  postLoading: false,
  postError: null,
  postSuccess: null,
  lastData: {},
};

const qrListSlice = createSlice({
  name: "qrList",
  initialState,
  reducers: {
    qrListStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    qrListSuccess(state, action) {
      state.loading = false;
      state.data = action.payload?.data?.result;
      state.success = action.payload.message;
    },
    qrListFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getMyDetailsStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getMyDetailsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
    },
    getMyDetailsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateqrListStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    updateqrListSuccess(state, action) {
      state.loading = false;
      state.editSuccess = action.payload.data.message;
    },
    updateqrListFailure(state, action) {
      state.loading = false;
      state.editError = action.payload;
    },
    changePasswordStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    changePasswordSuccess(state, action) {
      state.loading = false;
      state.passwordSuccess = action.payload.data.message;
    },
    changePasswordFailure(state, action) {
      state.loading = false;
      state.passwordError = action.payload;
    },
    generateQrStart(state, payload) {
      state.postLoading = true;
      state.postError = null;
    },

    generateQrSuccess(state, action) {
      state.postLoading = false;
      state.postSuccess = action.payload.data.message;
    },
    generateQrFailure(state, action) {
      state.postLoading = false;
      state.postError = action.payload;
    },

    getLastQrIdStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getLastQrIdSuccess(state, action) {
      state.loading = false;
      state.success = action.payload.data.message;
      state.lastData = action.payload;
    },
    getLastQrIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.editSuccess = null;
      state.editError = null;
      state.passwordSuccess = null;
      state.passwordError = null;
    },
  },
});

export const {
  qrListStart,
  qrListSuccess,
  qrListFailure,
  getMyDetailsStart,
  getMyDetailsSuccess,
  getMyDetailsFailure,
  updateqrListStart,
  updateqrListSuccess,
  updateqrListFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  generateQrFailure,
  generateQrSuccess,
  generateQrStart,
  getLastQrIdStart,
  getLastQrIdSuccess,
  getLastQrIdFailure,
  clearState,
} = qrListSlice.actions;

export default qrListSlice.reducer;
