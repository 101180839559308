import React, { Fragment } from 'react'
import ProfileHEader from './ProfileHEader'
import ProfileDetailListing from './ProfileDetailListing'

const ProfilePage = () => {
    return (
        <Fragment>
            <ProfileHEader />
            <ProfileDetailListing />
        </Fragment>
    )
}

export default ProfilePage
