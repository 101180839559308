import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FaqReducerEditFailure,
  FaqReducerEditStart,
  FaqReducerEditSuccess,
  FaqReducerFailure,
  FaqReducerStart,
  FaqReducerSuccess,
} from "../Reducers/FaqReducer.js";
import Cookies from "js-cookie";
import axios from "axios";
export const getFaqLisiting = createAsyncThunk("Faq", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(FaqReducerStart());
    const token = Cookies.get("token");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}FaqRoutes/GetFaqListing`,
      config
    );
    const responseData = await response;

    thunkAPI.dispatch(FaqReducerSuccess(responseData));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(FaqReducerFailure(error.response.data.message));

    throw error;
  }
});

export const updateFaq = createAsyncThunk(
  "Faq",
  async ({ id, values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(FaqReducerEditStart());
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}FaqRoutes/EditFaq/${id}`,
        values,
        config
      );
      const responseData = await response;

      thunkAPI.dispatch(FaqReducerEditSuccess(responseData));
      thunkAPI.dispatch(getFaqLisiting());
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(FaqReducerEditFailure(error.response.data.message));

      throw error;
    }
  }
);