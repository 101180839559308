import { Button, message } from 'antd'
import React, { Fragment } from 'react'

const DeleteModaal = ({handleClose, handleDelete, id}) => {

    const handleSubmit = () => {
        handleDelete(id);
        message.success('QR Deleted Succesfully!')
        handleClose()
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <h2>Delete QR Code</h2>
                    </div>

                    <div className="col-sm-12 surree">
                        <p>Are you sure you want to delete this data?</p>
                    </div>

                    <div className="col-sm-12 centrerYN">
                        <Button type='primary' className='deletewtmodalBTn' onClick={handleSubmit}>
                            Yes
                        </Button>

                        <Button type='primary' className='deletewtmodalBTn' onClick={handleClose}>
                            No
                        </Button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default DeleteModaal
