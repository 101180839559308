import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  success: null,
  error: null,
  data: {},
};

const ProfileUpdateSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    createProfileUpdateStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.success = null;
    },

    createProfileUpdateSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.success = "Update Successfully";
    //   console.log("asdsad" ,action.payload)
    },
    logoutProfileUpdateSuccess(state, action) {
      state.loading = false;
      state.data = {};
      state.success = action.payload;
      state.token = null;
    },

    createProfileUpdateFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearsuccess: (state) => {
      state.success = null;
    },
    clearState: (state) => {
        state.error = null;
        state.success = null;
        state.editSuccess = null;
        state.editError = null;
        state.passwordSuccess = null;
        state.passwordError = null;
      },
  },
});

export const {
  createProfileUpdateStart,
  createProfileUpdateSuccess,
  logoutProfileUpdateSuccess,
  createProfileUpdateFailure,
  clearError,
  clearsuccess,
  clearState,
} = ProfileUpdateSlice.actions;

export default ProfileUpdateSlice.reducer;