import React, { Fragment, useEffect } from 'react'
import { Form, Input } from 'antd'
import { Image } from "antd";

const QRDetailListing = ({ data }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        UserName: data?.QrCodes && data?.QrCodes[0]?.UserInfos?.Name,
        Password: data?.QrLink,
        Name: data?.UserQrCode?.Name,
        Email: data?.QrCodes && data?.QrCodes[0]?.UserInfos?.Email,
        PhoneNo: data?.createdAt,
      });
    }
  }, [data, form]);

  return (
    <Fragment>
      <div className="container-fluid qrr">
        <Form layout="vertical" autoComplete="off" form={form}>
          <div className="row noPad qrFOrm">
            <div className="col-sm-12 qrimagepreview">
              <div className="bgg">
                <Image src={data?.QrMedia} />
              </div>
              <p>QR Preview</p>
            </div>
            <div className="col-sm-6 nipus">
              <Form.Item label="User Name" name="UserName">
                <Input readOnly placeholder="User Name" />
              </Form.Item>
            </div>

            <div className="col-sm-6 nipus">
              <Form.Item label="Qr Link " name="Password">
                <Input readOnly placeholder="User Name" />
              </Form.Item>
            </div>

            <div className="col-sm-6 nipus">
              <Form.Item
                label="Customer Email"
                name="Email"
                rules={[
                  {
                    type: "email",
                    message: "Email is not valid",
                  },
                ]}
              >
                <Input readOnly placeholder="Customer Email" />
              </Form.Item>
            </div>

            <div className="col-sm-6 nipus">
              <Form.Item label="Created Date" name="PhoneNo">
                <Input readOnly placeholder="Phone Number" />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default QRDetailListing;