import { Button, message } from 'antd'
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { claimAndUnclaim } from '../../Redux/Actions/claimAndUnclaim';
import { clearState } from '../../Redux/Reducers/claimAndUnclaimReducer';
import { qrListing } from '../../Redux/Actions/qrListing';

const ClaimedUnclaimed = ({handleClose, data}) => {
    const dispatch = useDispatch();
    const {error, success} = useSelector((state) => state.claimAndUnclaim)
    const handleClaim = async () => {
        const id = data?._id;
        const flag = data?.Status ? false : true;
        console.log(id, flag)
        await dispatch(claimAndUnclaim({id, flag}));
    }


    useEffect(() => {
        if (error) {
            message.error(error);
            dispatch(clearState());
        }
        if (success) {
            message.success(success);
            dispatch(qrListing({}));
            dispatch(clearState());
            handleClose();
        }
    }, [error, success , dispatch])
    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <h2> Reset QR</h2>
            </div>

            <div className="col-sm-12 surree">
              <p>Are you sure you want to Reset this QR?</p>
            </div>

            <div className="col-sm-12 centrerYN">
              <Button
                type="primary"
                className="deletewtmodalBTn"
                onClick={handleClaim}
              >
                Yes
              </Button>

              <Button
                type="primary"
                className="deletewtmodalBTn"
                onClick={handleClose}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    );
}

export default ClaimedUnclaimed
