import { Button, Form, Input, message } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFaqLisiting, updateFaq } from "../../Redux/Actions/faq";
import { clearState } from "../../Redux/Reducers/FaqReducer";
import Loader from "../Loader";

const EditFaqForm = ({ editData, handleClose }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { error, success, loading } = useSelector((state) => state.FaqReducer);

  const onFinish = (values) => {
    const id = editData._id;
    dispatch(updateFaq({ id, values }));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (editData) {
      form.setFieldValue({
        Question: editData.Question,
        Answer: editData.Answer,
      });
    }
  }, [editData, form]);

  useEffect(() => {
    if (error) {
      message.error(error);
    }
    if (success) {
      message.success(success);
      handleClose();
      dispatch(clearState());
    }
  }, [success, error]);

  return (
    <Fragment>
      <div className="container-fluid">
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          initialValues={editData}
          form={form}
          autoComplete="off"
        >
          <div className="row uploadImageForm">
            {/* <div className="col-sm-12 uploadHeading">
              <p>Edit Faq</p>
            </div> */}

            <div className="col-sm-12 videoLink">
              <Form.Item
                name="Question"
                label={"Question"}
                rules={[
                  {
                    required: true,
                    message: "Please enter Question!",
                  },
                ]}
              >
                <Input placeholder={"Question"} />
              </Form.Item>
            </div>

            <div className="col-sm-12">
              <Form.Item
                name="Answer"
                label={"Answer"}
                rules={[
                  {
                    required: true,
                    message: "Please enter Answer!",
                  },
                ]}
              >
                <Input.TextArea rows={8} placeholder={"Answer"} />
              </Form.Item>
            </div>

            <div className="col-sm-12 submitt">
              <Button type="primary" htmlType="submit">
                {loading ? <Loader /> : "Update"}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default EditFaqForm;
