import React, { Fragment } from 'react'

const QRdetailHeader = ({Name}) => {
    return (
        <Fragment>
            <div className="container-fluid dasshboard QR">
                <div className="row">
                    <div className="col-sm-12">
                        <p>QR Management </p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default QRdetailHeader
