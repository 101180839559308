import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  singleListFailure,
  singleListStart,
  singleListSuccess,
} from "../Reducers/SingleListReducer";
import Cookies from "js-cookie";
import axios from "axios";


export const SingleListing = createAsyncThunk("auth", async (id, thunkAPI) => {
  try {
    thunkAPI.dispatch(singleListStart());
    const token = Cookies.get("token");
    console.log(token, "<=========token");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/AdminSingleQrCode/${id}`,
      config
    );
    const responseData = await response;

    thunkAPI.dispatch(singleListSuccess(responseData));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(singleListFailure(error.response.data.message));

    throw error;
  }
});


