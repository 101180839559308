import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Loader = () => {
  return (
    <Spin
      className="loadds"
      style={{ color: "#fff" }}
      indicator={<LoadingOutlined spin />}
    />
  );
};

export default Loader;
