import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  data: [],
};

const FaqSlice = createSlice({
  name: "Faq",
  initialState,
  reducers: {
    FaqReducerStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    FaqReducerSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data?.result?.items;
      state.success = action.payload.data.message;
    },
    FaqReducerFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    FaqReducerEditStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    FaqReducerEditSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data?.result?.items;
      state.success = action.payload.data.message;
    },
    FaqReducerEditFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.loading = false;
    },
  },
});

export const {
  FaqReducerStart,
  FaqReducerSuccess,
  FaqReducerFailure,
  FaqReducerEditFailure,
  FaqReducerEditSuccess,
  FaqReducerEditStart,
  clearState,
} = FaqSlice.actions;

export default FaqSlice.reducer;
