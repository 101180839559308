import { createAsyncThunk } from "@reduxjs/toolkit";
import { createForgetPassStart, createForgetPassSuccess, createForgetPassFailure } from "../Reducers/ForgetPassReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const ForgetPass = createAsyncThunk(
  "ForgetPass",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(createForgetPassStart());

      const token = Cookies.get("Token"); // Retrieve the token from the cookie

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}AdminRoutes/ResetPassword/${token}`,
        values
      );

      thunkAPI.dispatch(createForgetPassSuccess(response.data));

      return response.data;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(createForgetPassFailure(error.response.data.message)); // Dispatch the failure action
      return thunkAPI.rejectWithValue(error.response.data.message); // Return a rejected action with the error message
    }
  }
);