import React, { Fragment, useEffect } from 'react';
import logo from '../../Assets/logoLogin.png';
import { Button, Form, Input, message } from 'antd';
import frwd from '../../Assets/rightArrow.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearState } from '../../Redux/Reducers/ResetCodeReducers';
import { ResetCode } from '../../Redux/Actions/ResetCode';
import Cookies from 'js-cookie';

const ResetCodeForm = () => {
    const navigate = useNavigate();
    const { error, success, token } = useSelector((state) => state.Rcode);
    const dispatch = useDispatch();

    const onFinish = async (values) => {
        await dispatch(ResetCode({ values }));
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    // Response
    useEffect(() => {
        if (error) {
            message.error(error);
            dispatch(clearState());
        }
        if (success) {
            message.success(success);

            // Save token in cookie
            if (token) {
                console.log('Saving token:', token);
                Cookies.set('token', token, { expires: 7 }); // Cookie expires in 7 days
            }

            navigate("/reset-password");
            dispatch(clearState());
        }
    }, [error, success, token, dispatch]);

    return (
        <Fragment>
            <div className="container-fluid loginForm">
                <Form
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='off'
                    layout='vertical'
                >
                    <div className="row">
                        <div className="col-sm-12 cennn">
                            <img src={logo} alt='logo'/>
                        </div>

                        <div className="col-sm-12 whiteBorder">
                            <Form.Item
                                name='Code'
                                label='Reset Code'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter code!'
                                    }
                                ]}
                            >
                                <Input placeholder='Reset Code' />
                            </Form.Item>
                        </div>
                        <div className="col-sm-12">
                            <Button htmlType='submit' type='primary'>Reset <img src={frwd} alt='forward'/></Button>
                        </div>
                        <div className="col-sm-12 cennn">
                            <p>Do you want to Login? <span style={{ textDecoration: 'underline' }} onClick={() => navigate('/')}>Login</span></p>
                        </div>
                    </div>
                </Form>
            </div>
        </Fragment>
    );
}

export default ResetCodeForm;