import React, { Fragment, useEffect } from 'react'
import QRdetailHeader from './QRdetailHeader'
import { useParams } from 'react-router-dom';
import QRDetailListing from './QRDetailListing';
import { useDispatch, useSelector } from 'react-redux';
import { SingleListing } from '../../Redux/Actions/SingleList';

const QRDetaail = () => {
    const { id } = useParams();
    const { data } = useSelector((state) => state.SingleList);
    const Dispatch = useDispatch();
    useEffect(() => {
        Dispatch(SingleListing( id ));
    }, [Dispatch , id]);

    return (
        <Fragment>
            <QRdetailHeader Name={data?.UserName}/>
            <QRDetailListing data={data}/>
        </Fragment>
    )
}

export default QRDetaail
