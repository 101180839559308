import { Button, Form, Input, message } from 'antd'
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ResetPass } from '../../Redux/Actions/ResetPass';
import { clearState } from '../../Redux/Reducers/ResetPassReducer';

const ChangePasswordModaal = ({ handleClose }) => {

    const { error, success } = useSelector((state) => state.Reset);

    const Dispatch = useDispatch();
    const onFinish = async (values) => {
        await Dispatch(ResetPass({ values }));
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (error) {
            message.error(error);
            Dispatch(clearState());
        }
        if (success) {
            message.success(success);
            Dispatch(clearState());
            handleClose();
        }
    }, [error, success, Dispatch]);

    const validatePassword = (_, value) => {
        if (value && value.length < 6) {
            return Promise.reject("Password must be at least 6 characters long.");
        }
        return Promise.resolve();
    };

    return (
        <Fragment>
            <div className="container-fluid">
                <Form
                    name='generate'
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout='vertical'
                    autoComplete='off'
                >
                    <div className="row">
                        <div className="col-sm-12">
                            <Form.Item
                                name='OldPassword'
                                label='Old Password'
                                rules={[
                                    { 
                                        required: true, 
                                        message: "Please input old password!" 
                                    }
                                ]}
                            >
                                <Input.Password placeholder='Old Password' />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12">
                            <Form.Item
                                name="Password"
                                label='New Password'
                                rules={[
                                    { 
                                        required: true, 
                                        message: "Please input new password!" 
                                    },
                                    { 
                                        validator: validatePassword 
                                    }
                                ]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12">
                            <Form.Item
                                name="ConfirmPassword"
                                label='Confirm Password'
                                rules={[
                                    {
                                        required: true,
                                        message: "Please confirm your Password",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("Password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject("The two passwords do not match");
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Confirm Password" />
                            </Form.Item>
                        </div>

                        <div className="col-sm-12 ccenter">
                            <Button htmlType='submit' type='primary' className='deletewtmodalBTn'>
                                Save
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default ChangePasswordModaal
