import React, { Fragment, useEffect } from 'react'
import logo from '../../Assets/logoLogin.png'
import { Button, Form, Input, message } from 'antd'
import frwd from '../../Assets/rightArrow.png'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearState } from '../../Redux/Reducers/ForgetPassReducer'
import { ForgetPass } from '../../Redux/Actions/ForgetPass'
import Cookies from 'js-cookie';

const NewPasswordForm = () => {
    const navigate = useNavigate()

    const { error, success } = useSelector((state) => state.Fpass);


    const Dispatch = useDispatch();
    const onFinish = async (values) => {
      await Dispatch(ForgetPass({ values }));
    };
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };

    // Response
    useEffect(() => {
      if (error) {
        message.error(error);
        Dispatch(clearState());
      }
      if (success) {
        message.success(success);
        Cookies.remove("Token");
        navigate("/");
        Dispatch(clearState());
      }
    }, [error, success, Dispatch]);


        //   validation

        const validatePassword = (_, value) => {
            if (value && value.length < 6) {
                return Promise.reject("Password must be at least 6 characters long.");
            }
            return Promise.resolve();
        };
    return (
        <Fragment>
            <div className="container-fluid loginForm ">
                <Form
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='off'
                    layout='vertical'
                >
                    <div className="row">
                        <div className="col-sm-12 cennn">
                            <img src={logo} alt='asd'/>
                        </div>

                        <div className="col-sm-12 newPasss">
                        <Form.Item
                                name="firstPassword"
                                label='New Password'
                                rules={[
                                    { required: true, message: "Please input your password!" },
                                    { validator: validatePassword },
                                ]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                        </div>
                        <div className="col-sm-12">
                        <Form.Item
                                name="confirmPassword"
                                label='Confirm Password'
                                rules={[
                                    {
                                        required: true,
                                        message: "Please confirm your Password",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("firstPassword") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject("The two passwords do not match");
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Confirm Password" />
                            </Form.Item>
                        </div>
                        <div className="col-sm-12">
                            <Button htmlType='submit' type='primary'>Reset <img src={frwd} alt='asd'/></Button>
                        </div>
                        <div className="col-sm-12 cennn">
                            <p>Do you want to Login? <span style={{textDecoration: 'underline'}} onClick={() => navigate('/')}>Login</span></p>
                        </div>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}

export default NewPasswordForm
