import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  success: null,
  error: null,
  data: {},
};

const claimAndUnclaimSlice = createSlice({
    name: "claimAndUnclaim",
    initialState,
    reducers: {
        claimAndUnclaimStart(state, payload) {
            state.loading = true;
            state.error = null;
            state.success = null;
        },
        claimAndUnclaimSuccess(state, action) {
            state.loading = false;
            state.isAuthenticated = true;
            state.success = action.payload.data.message;
            //   console.log("asdsad" ,action.payload)
        },
        logoutclaimAndUnclaimSuccess(state, action) {
            state.loading = false;
            state.data = {};
            state.success = action.payload;
            state.token = null;
        },
        claimAndUnclaimFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
            state.success = null;
        },
        clearError: (state) => {
            state.error = null;
        },
        clearsuccess: (state) => {
            state.success = null;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.editSuccess = null;
            state.editError = null;
            state.passwordSuccess = null;
            state.passwordError = null;
        },
    },
});

export const {
  claimAndUnclaimStart,
  claimAndUnclaimSuccess,
  logoutclaimAndUnclaimSuccess,
  claimAndUnclaimFailure,
  clearError,
  clearsuccess,
  clearState,
} = claimAndUnclaimSlice.actions;

export default claimAndUnclaimSlice.reducer;