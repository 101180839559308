import { createAsyncThunk } from "@reduxjs/toolkit";
import { createResetPassStart, createResetPassSuccess, createResetPassFailure } from "../Reducers/ResetPassReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const ResetPass = createAsyncThunk(
  "ResetPass",
  async ({ values }, thunkAPI) => {
    try {
      console.log("data", values);
      thunkAPI.dispatch(createResetPassStart());
      const token = Cookies.get("token");
     
const config ={
    headers: {
        Authorization: token,
      },
}
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}AdminRoutes/ChangePassword`,
        values,
        config
      );

      thunkAPI.dispatch(createResetPassSuccess(response.data));

      return response.data;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(createResetPassFailure(error.response.data.message)); // Dispatch the failure action
      return thunkAPI.rejectWithValue(error.response.data.message); // Return a rejected action with the error message
    }
  }
);