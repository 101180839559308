import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    authLoginStart,
    authLoginSuccess,
    authLoginFailure,
} from "../Reducers/authReducer";
import Cookies from "js-cookie";

import axios from "axios";

export const Login = createAsyncThunk("auth", async (values, thunkAPI) => {
    try {
      thunkAPI.dispatch(authLoginStart());
  
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}AdminRoutes/LoginAdmin`,
        values
      );
      const responseData = await response;
  
      thunkAPI.dispatch(authLoginSuccess(responseData));
      Cookies.set("token", responseData.data.data);
  
      return responseData;
    } catch (error) {
      thunkAPI.dispatch(authLoginFailure(error.response.data.message));
  
      throw error;
    }
  });
