import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: null,
  editError: null,
  editSuccess: null,
  passwordError: null,
  passwordSuccess: null,
  data: {},
  token: null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    profileStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    profileSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
      state.success = action.payload.data.message;
    },
    profileFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getMyDetailsStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getMyDetailsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
    },
    getMyDetailsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateProfileStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    updateProfileSuccess(state, action) {
      state.loading = false;
      state.editSuccess = action.payload.data.message;
    },
    updateProfileFailure(state, action) {
      state.loading = false;
      state.editError = action.payload;
    },
    changePasswordStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    changePasswordSuccess(state, action) {
      state.loading = false;
      state.passwordSuccess = action.payload.data.message;
    },
    changePasswordFailure(state, action) {
      state.loading = false;
      state.passwordError = action.payload;
    },
    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.editSuccess = null;
      state.editError = null;
      state.passwordSuccess = null;
      state.passwordError = null;
    },
  },
});

export const {
  profileStart,
  profileSuccess,
  profileFailure,
  getMyDetailsStart,
  getMyDetailsSuccess,
  getMyDetailsFailure,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  clearState,
} = profileSlice.actions;

export default profileSlice.reducer;
