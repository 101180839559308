import React, { Fragment, useEffect, useState } from 'react'
import Loggin from '../Components/Logins/Loggin'
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Login() {

    const navigate = useNavigate();

    const [token, setToken] = useState();
    const tokens = Cookies.get("token");
    useEffect(() => {
      setToken(tokens);
    }, [tokens]);

    
    useEffect(() => {
        if (token) {
          navigate("/qr-management");
        }
      }, [token]);
    return (
        <Fragment>
            <div className='container-fluid login'>
                <div className="row login">
                    <Loggin />
                </div>
            </div>
        </Fragment>
    )
}

export default Login