import { Button, Form, Input, Radio, message } from "antd";
import QRCodeStyling from "qr-code-styling";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { generateQr, getLastQrId } from "../../Redux/Actions/qrListing";
import Loader from "../Loader";
import { ProgressBar } from "react-bootstrap";
// import ProgressBar from "../ProgressBar";

const GenerateQR = ({ handleClose }) => {
  const qrRef = useRef(null);
  const [link, setLink] = useState(uuidv4());

  const [frame, setFrame] = useState(false);
  const [successCount, setSuccessCount] = useState(0);
  const [totalRequests, setTotalRequests] = useState(0);
  const [QrCountFlag, SetQrCountFlag] = useState(false);
  const [QrGenrated, SetQrGenerated] = useState(0);
  const [qrCode, setQrCode] = useState(
    new QRCodeStyling({
      width: 100,
      height: 100,
      dotsOptions: {
        color: frame ? "#000" : "#000",
        type: "square",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 20,
      },
      cornersDotOptions: {
        type: "square",
        gradient: {
          type: "linear",
          colorStops: [{ color: frame ? "#000" : "#000" }],
        },
        color: frame ? "#000" : "#000",
      },
      shape: "circle",
      cornersSquareOptions: {
        type: "square",
      },
      margin: 5,
      qrOptions: {
        errorCorrectionLevel: "H",
      },
      type: "svg",
      backgroundOptions: {
        round: 10,
      },
    })
  );
  // const [qrCode, setQrCode] = useState(
  // new QRCodeStyling({
  //   width: 300,
  //   height: 300,
  //   dotsOptions: {
  //     color: "#000",
  //     type: "square",
  //   },
  //   imageOptions: {
  //     crossOrigin: "anonymous",
  //     margin: 20,
  //   },
  //   cornersDotOptions: {
  //     type: "square",
  //     gradient: {
  //       type: "linear",
  //       colorStops: [{ color: "#000" }],
  //     },
  //     color: "#000",
  //   },
  //   shape: "circle",
  //   cornersSquareOptions: {
  //     type: "square",
  //   },
  //   margin: 5,
  //   qrOptions: {
  //     errorCorrectionLevel: "H",
  //   },
  //   type: "svg",
  //   backgroundOptions: {
  //     round: 10,
  //   },
  // })
  // );

  const { postLoading, postSuccess, postError, lastData } = useSelector(
    (state) => state.QRlisting
  );
  const dispatch = useDispatch();
  const handleChangeRadio = (e) => {
    setFrame(e.target.value);
  };
  const onFinish = async (values) => {
    try {
      const { number } = values;
      setSuccessCount(0);
      setTotalRequests(number);
      SetQrCountFlag(true);
  
      // Fetch the last QR ID from the database
      let lastQrId =
        lastData?.data?._id || "afc06471-a8cf-4dd0-8399-6571fe0200000";
  
      for (let i = 0; i < number; i++) {
        const newQrId = await generateQrId(lastQrId); // Generate new QR ID based on last ID
        lastQrId = newQrId; // Update lastQrId to the new ID for the next iteration
        const fullUrl = `${process.env.REACT_APP_REDIRECT_URL}${newQrId}`;
  
        const newqr = new QRCodeStyling({
          width: 135,
          height: 135,
          data: fullUrl,
          dotsOptions: {
            color: frame ? "#fff" : "#fff",
            type: "square",
          },
          imageOptions: {
            crossOrigin: "anonymous",
            margin: 20,
          },
          backgroundOptions: null,
          type: "svg",
        });
  
        setQrCode(newqr);
  
        const blobData = await newqr.getRawData("png");
        const svgData = await newqr.getRawData("svg");
  
        const arrayBuffer = await svgData.arrayBuffer();
        const svgString = new TextDecoder().decode(arrayBuffer);
  
        const modifiedSvgString = svgString.replace(
          /<path/g,
          '<path fill="#ff0000"'
        );
  
        // Convert the modified SVG string back to Blob
        const modifiedSvgBlob = new Blob([modifiedSvgString], {
          type: "image/svg+xml",
        });
  
        const file = new File([blobData], `image_${i + 1}.png`);
        const svgFile = new File([modifiedSvgBlob], `svg_${i + 1}.svg`);
        const formData = new FormData();
        formData.append("Link", newQrId);
        formData.append("image", file);
        formData.append("frame", frame);
        formData.append("svgPath", svgFile);
  
        setLink(uuidv4());
        const res = await dispatch(generateQr({ formData }));
        SetQrGenerated(i + 1);
      }
      message.success("QR Generation Successful");
      handleClose();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const numberValidator = (_, value) => {
    const pattern = /^-?\d*(\.\d*)?$/;
    if (
      !value ||
      (pattern.test(value) && Number(value) >= 1 && Number(value) <= 20)
    ) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Number must be between 1 and 20 and cannot contain letters or special characters."
    );
  };

  useEffect(() => {
    if (qrRef.current && !qrRef.current.firstChild) {
      qrCode.append(qrRef.current);
    }
  }, [frame]);

  useEffect(() => {
    if (postSuccess) {
      setSuccessCount((prevCount) => prevCount + 1);
    }
  }, [postSuccess]);
  useEffect(() => {
    if (postError) {
      message.error(postError);
    }
  }, [postError]);

  useEffect(() => {
    const fullUrl = `${process.env.REACT_APP_REDIRECT_URL}${link}`;
    qrCode.update({
      data: fullUrl,
    });
  }, [link]);
  const progressPercentage = (QrGenrated / totalRequests) * 100 || 0;

  const incrementQrIdNumber = (lastQrId) => {
    if (!lastQrId) {
      throw new Error("lastQrId is not defined");
    }

    const numericPart = lastQrId.slice(-4);
    let newNumber = parseInt(numericPart, 16) + 1;

    newNumber = newNumber.toString(16).padStart(4, "0").toUpperCase();

    return newNumber;
  };

  const generateQrId = async (lastQrId) => {
    const baseUuid = uuidv4();

    if (!lastQrId) {
      console.log("No previous QR ID found. Generating a new base UUID.");
      return baseUuid; // or any other logic you want to apply for the first QR ID
    }

    const newNumber = incrementQrIdNumber(lastQrId);

    // Combine UUID with the new number
    const newQrId = `${baseUuid.substring(0, baseUuid.length - 4)}${newNumber}`;

    return newQrId;
  };
  useEffect(() => {
    dispatch(getLastQrId());
  }, [dispatch]);

  useEffect(() => {
    generateQrId().then((newQrId) => {
      setLink(newQrId);
    });
  }, []);
  return (
    <Fragment>
      <div className="container-fluid">
        <div ref={qrRef} style={{ display: "none" }}></div>

        <h2 className="mainHead">Select Your QR And Generate</h2>
        <Form
          name="generate"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          autoComplete="off"
        >
          <div className="row">
            <div className="col-sm-12 imageSample">
              <Form.Item
                name="QR"
                rules={[
                  {
                    required: true,
                    message: "Please select any QR",
                  },
                ]}
              >
                <Radio.Group onChange={handleChangeRadio} value={frame}>
                  <Radio value={false}>
                    <img
                      className="QRRadio"
                      src="https://junzhiassets.s3.amazonaws.com/StaticData/squareframe1.svg"
                    />
                  </Radio>
                  <Radio value={true}>
                    <img
                      className="QRRadio"
                      src="https://junzhiassets.s3.amazonaws.com/StaticData/squareframe2.svg"
                    />
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="col-sm-12">
              {/* <h2>Generate QR Code</h2> */}
              {QrCountFlag ? <h3>Qr code Generated {QrGenrated}</h3> : <></>}
            </div>
            <div className="col-sm-12">
              {QrCountFlag ? (
                <ProgressBar
                  now={progressPercentage}
                  label={`${progressPercentage}%`}
                  animated
                  variant="success"
                />
              ) : (
                <></>
              )}
            </div>
            <div className="col-sm-12">
              <Form.Item
                name="number"
                label="No of QR Required (Number in between 1 and 20)"
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of QR codes required!",
                  },
                  {
                    validator: numberValidator,
                  },
                ]}
              >
                <Input placeholder="No of QR Required" />
              </Form.Item>
            </div>

            <div className="col-sm-12 ccenter">
              <Button
                htmlType="submit"
                type="primary"
                className="deletewtmodalBTn"
                disabled={postLoading}
              >
                {postLoading ? <Loader /> : "Generate"}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Fragment>
  );
};

export default GenerateQR;
