import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Count } from '../../Redux/Actions/Count';

const DashboardData = () => {

    const dispatch = useDispatch();

    const { data } = useSelector((state) => state.Dcount);
    useEffect(() => {
      dispatch(Count());
    }, [dispatch]);


    return (
      <Fragment>
        <div className="container-fluid dasshboard">
          <div className="row">
            <div className="col-sm-12">
              <p>Welcome to the Dashboard</p>
            </div>
            <div className="col-sm-3 boxxx">
              <p className="nunmber">No of QR in System</p>
              <p className="bbold">{data?.NoOfQrInSystem}</p>
            </div>
            <div className="col-sm-3 boxxx">
              <p className="nunmber">No of QR Active</p>
              <p className="bbold">{data?.NoOfQrClaimed}</p>
            </div>
          </div>
        </div>
      </Fragment>
    );
}

export default DashboardData
