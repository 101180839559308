import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CountFailure,
  CountStart,
  CountSuccess,
} from "../Reducers/CountReducers";
import Cookies from "js-cookie";
import axios from "axios";
export const Count = createAsyncThunk("auth", async (title, thunkAPI) => {
  try {
    thunkAPI.dispatch(CountStart());
    const token = Cookies.get("token");
    console.log(token, "<=========token");
    const config = {
      headers: {
        Authorization: token,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/QrCodeDashboardCount`,
      config
    );
    const responseData = await response;

    thunkAPI.dispatch(CountSuccess(responseData));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(CountFailure(error.response.data.message));

    throw error;
  }
});
