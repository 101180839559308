import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = {
  loading: false,
  error: null,
  success: null,
  editError: null,
  editSuccess: null,
  passwordError: null,
  passwordSuccess: null,
  data: {},
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authLoginStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    authLoginSuccess(state, action) {
      state.loading = false;
      state.token = action.payload.data.token;

      state.success = action.payload.data.message;
    },
    authLoginFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getMyDetailsStart(state, payload) {
      state.loading = true;
      state.error = null;
    },

    getMyDetailsSuccess(state, action) {
      state.loading = false;
      state.data = action.payload.data.data;
    },
    getMyDetailsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateProfileStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },

    updateProfileSuccess(state, action) {
      state.loading = false;
      state.editSuccess = action.payload.data.message;
    },
    updateProfileFailure(state, action) {
      state.loading = false;
      state.editError = action.payload;
    },
    changePasswordStart(state, payload) {
      state.loading = true;
      state.editError = null;
    },
    logOutSuccess(state) {
      state.data = null;
      state.token = null;
      state.message = null;
      console.log("chala");
      Cookies.remove("token");
    },
    changePasswordSuccess(state, action) {
      state.loading = false;
      state.passwordSuccess = action.payload.data.message;
    },
    changePasswordFailure(state, action) {
      state.loading = false;
      state.passwordError = action.payload;
    },
    clearState: (state) => {
      state.error = null;
      state.success = null;
      state.editSuccess = null;
      state.editError = null;
      state.passwordSuccess = null;
      state.passwordError = null;
    },
  },
});

export const {
  authLoginStart,
  authLoginSuccess,
  authLoginFailure,
  getMyDetailsStart,
  getMyDetailsSuccess,
  getMyDetailsFailure,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  clearState,
  logOutSuccess,
} = authSlice.actions;

export default authSlice.reducer;
