import React, { Fragment } from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import Headers from '../Components/Headerr/Headers'
import QRHeader from '../Components/QRManageement/QRHeader'
import Listing from '../Components/QRManageement/Listing'

function QRManagement() {
    return (
        <Fragment>
            <div class="container-fluid main">
                <div class="row">
                    <div className='col-sm-3 sideebarr'>
                        <Sidebar />
                    </div>    

                    <div className='col-sm-9 sideebarr'>
                        <Headers />
                        <QRHeader />
                        <Listing />
                    </div>   
                </div>    
            </div>
        </Fragment>
    )
}

export default QRManagement