import { createAsyncThunk } from "@reduxjs/toolkit";
import { claimAndUnclaimFailure, claimAndUnclaimStart, claimAndUnclaimSuccess } from "../Reducers/claimAndUnclaimReducer";
import Cookies from "js-cookie";
import axios from "axios";
export const claimAndUnclaim = createAsyncThunk("auth", async ({id, flag}, thunkAPI) => {
    try {
        thunkAPI.dispatch(claimAndUnclaimStart());
        const token = Cookies.get("token");
        const config = {
        headers: {
            Authorization: token,
        },
        }; 

        const response = await axios.put(
            `${process.env.REACT_APP_BASE_URL}QrCodeRoutes/ClaimAndUnClaimQr/${id}`,
            {flag: flag},
            config
        );
        const responseData = await response;

        thunkAPI.dispatch(claimAndUnclaimSuccess(responseData));
        // dispatch()

        return responseData;
    } catch (error) {
        thunkAPI.dispatch(claimAndUnclaimFailure(error.response.data.message));

        throw error;
    }
});
