import React, { Fragment } from 'react'
import NewPasswordForm from '../Components/NewPasswordd/NewPasswordForm'

function NewPassword() {
    return (
        <Fragment>
            <div className='container-fluid login'>
                <div className="row login">
                    <NewPasswordForm />
                </div>
            </div>
        </Fragment>
    )
}

export default NewPassword