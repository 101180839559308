import React, { Fragment, useEffect, useState } from 'react'
import proffile from '../../Assets/av.jpeg'
import { useDispatch, useSelector } from 'react-redux';
import { Profile } from '../../Redux/Actions/Profile';
import { Button } from 'antd';
import { Offcanvas } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar'

const Headers = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { data } = useSelector((state) => state.profileget);

    useEffect(() => {
      dispatch(Profile());
    }, [dispatch]);

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row headeerr desktop">
                    <div className="col-sm-12">
                        <div>
                            <img src={proffile} alt="profile" className="profile" />
                            <p>{data?.FirstName} {data?.LastName}</p>
                        </div>
                    </div>
                </div>

                <div className="row headeerr mobile">
                    <div className="col-sm-12">
                        <div>
                            <Button onClick={handleShow}>
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
                                    <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z" fill='white'></path>
                                </svg>
                            </Button>
                        </div>
                        <div>
                            <img src={proffile} alt="profile" className="profile" />
                            <p>{data?.FirstName} {data?.LastName}</p>
                        </div>
                    </div>
                </div>

                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Sidebar />
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </Fragment>
    )
}

export default Headers
