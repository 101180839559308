import { Button, Input, Select } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import GenerateQR from './GenerateQR';
import closeBtn from '../../Assets/cross.png'
import { useDispatch, useSelector } from 'react-redux';
import { qrListing } from '../../Redux/Actions/qrListing';
import axios from "axios";
import Loader from '../Loader';

const { Search } = Input;

const QRFilter = ({
  
  downloadImage,
  HandlePrintReadyPDF,
  loader
}) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [UserName, setUserName] = useState("");
  const dispatch = useDispatch();
  const [Status, setStatus] = useState("");
  const [IsActive, setIsActive] = useState("");
  const [downloadFormat, setDownloadFormat] = useState("svg"); // Default to SVG format

  const { data } = useSelector((state) => state.QRlisting);
  useEffect(() => {
    dispatch(qrListing({ UserName, Status, IsActive }));
  }, [dispatch, UserName, Status, IsActive]);

  const handleSearch = (e) => {
    setUserName(e.target.value);
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };
  const handleDownload = () => {
    if (downloadFormat === "svg") {
      downloadImage();
    } else if (downloadFormat === "pdf") {
      HandlePrintReadyPDF();
    }
  };
  const handleFormatChanges = (value) => {
    setDownloadFormat(value);
  };

  const handleActiveInactiveChange = (value) => {
    setIsActive(value);
  };

  return (
    <Fragment>
      <div className="container-fluid qrfilter">
        <div className="row">
          <div className="col-sm-3">
            <Select
              placeholder="Sold/Unsold"
              value={Status}
              onChange={handleStatusChange}
            >
              <Select.Option value={""}>All</Select.Option>
              <Select.Option value={"true"}>Sold</Select.Option>
              <Select.Option value={"false"}>Unsold</Select.Option>
            </Select>
          </div>
          <div className="col-sm-3">
            <Select
              placeholder="Active/Inactive"
              value={IsActive}
              onChange={handleActiveInactiveChange}
            >
              <Select.Option value={""}>All</Select.Option>
              <Select.Option value={"true"}>Active</Select.Option>
              <Select.Option value={"false"}>Inactive</Select.Option>
            </Select>
          </div>{" "}
          <div className="col-sm-2">
            <Select
              defaultValue="svg"
              value={downloadFormat}
              onChange={handleFormatChanges}
              placeholder="Download Format"
            >
              <Select.Option value="svg">Download As SVG</Select.Option>
              <Select.Option value="pdf">Download As PDF</Select.Option>
            </Select>
          </div>
          <div className="col-sm-2">
            <Button type="primary" onClick={handleDownload} disabled={loader}>
              {loader ? <Loader /> : "Download QR"}
            </Button>
          </div>
          <div className="col-sm-2">
            <Button type="primary" onClick={handleShow}>
              Generate QR
            </Button>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="deleteModal generateQR"
      >
        <Modal.Header>
          <Button onClick={handleClose}>
            <img src={closeBtn} alt="closebtn" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <GenerateQR handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default QRFilter
