import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  loading: false,
  success: null,
  error: null,
  data: {},
};

const ForgetPassSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    createForgetPassStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.success = null;
    },

    createForgetPassSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.success = "Update Successfully";
    //   console.log("asdsad" ,action.payload)
    },
    logoutForgetPassSuccess(state, action) {
      state.loading = false;
      state.data = {};
      state.success = action.payload;
      state.token = null;
    },

    createForgetPassFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearsuccess: (state) => {
      state.success = null;
    },
    clearState: (state) => {
        state.error = null;
        state.success = null;
        state.editSuccess = null;
        state.editError = null;
        state.passwordSuccess = null;
        state.passwordError = null;
      },
  },
});

export const {
  createForgetPassStart,
  createForgetPassSuccess,
  logoutForgetPassSuccess,
  createForgetPassFailure,
  clearError,
  clearsuccess,
  clearState,
} = ForgetPassSlice.actions;

export default ForgetPassSlice.reducer;